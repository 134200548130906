import {type ReactNode, useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useRouter} from '@halp/ui/router';
import {prettyPrint} from '@halp/util';
import {FormErrorMessage} from './components';
import {ResponseError} from './ResponseError';
import type {
	DeepPartial,
	UseFormSetError,
	FieldValues,
	GlobalError,
} from 'react-hook-form';

export interface Props<Vars extends FieldValues, Data> {
	defaultValues?: DeepPartial<Vars>;
	children?: ReactNode;
	redirect?: string;
	refresh?: boolean;
	resetForm?: boolean;

	onSubmit?: (vars: Vars) => void;
	onResponse?: (data?: Data) => void;
	// TODO: @jtsmills
	onError?: (errors: unknown) => void;
	onSuccess?: (data: Data) => void;
	handleSubmit?: (vars: Vars, setError: UseFormSetError<Vars>) => Promise<Data>;
	className?: string;
}

function ServerErrors({
	serverError,
}: {
	serverError: GlobalError | GlobalError[];
}) {
	const errors = Array.isArray(serverError) ? serverError : [serverError];

	return (
		<>
			{errors.map((error, i) => (
				<FormErrorMessage key={i}>
					{prettyPrint(error.message)}
				</FormErrorMessage>
			))}
		</>
	);
}

export function Form<Vars extends FieldValues, Data>({
	defaultValues,
	redirect,
	refresh,
	resetForm,
	children,
	onSubmit,
	onResponse,
	onSuccess,
	handleSubmit,
	className,
}: Props<Vars, Data>) {
	const router = useRouter();
	const methods = useForm<Vars>({
		mode: 'onBlur',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		defaultValues: defaultValues as any,
	});

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		methods.reset(defaultValues as any);
	}, [methods, defaultValues]);

	const complete = (data?: Data) => {
		if (onResponse != null) {
			onResponse(data);
		}

		if (data) {
			if (onSuccess != null) {
				onSuccess(data);
			}

			if (redirect != null) {
				router.push(redirect);
			} else if (refresh) {
				router.reload();
			} else if (resetForm) {
				methods.reset();
			}
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const formHandleSubmit: any = (vars: Vars) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		methods.clearErrors('server' as any);
		if (onSubmit) {
			onSubmit(vars);
		}

		if (handleSubmit) {
			handleSubmit(vars, methods.setError)
				.then(complete)
				.catch((e) => {
					// 200 is okay :)
					// 422 is a validation error, so we don't want to throw for that
					// 401 is an unauthenticated error, so we don't want to throw for that
					if (
						e instanceof ResponseError &&
						(e.statusCode === 200 ||
							e.statusCode === 401 ||
							e.statusCode === 422)
					) {
						return;
					}
					throw e;
				});
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const serverError = (methods.formState.errors as any).server;

	return (
		<FormProvider {...methods}>
			{serverError ? <ServerErrors serverError={serverError} /> : null}
			<form
				onSubmit={(e) => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					methods.clearErrors('server' as any);
					return methods.handleSubmit(formHandleSubmit)(e);
				}}
				className={className}
			>
				{children}
			</form>
		</FormProvider>
	);
}
